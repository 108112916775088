@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

.App {
  text-align: justify;
  min-height: 100vh;
  display: flex;
}
.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;      /* Center children horizontally */
  justify-content: center;  /* Center children vertically */
  margin-right: auto;
  margin-left: auto;
}

.Form-header {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  height: 100px;
  background: #FF6B5B;
  border-radius: 20px;
  padding-left: 5% !important;
  padding-right: 75% !important;
}
.Form-header.Peach{
  background: #FF8C5B;
}
.Form-body {
  position: relative;
  justify-content: flex-start;
  min-height: 439px;
  background: linear-gradient(50.1deg, #FFA05B 22.86%, #FF6B5B 68.54%);
  border-radius: 20px;
  display: block;
  padding: 5% !important;
}
.Form-body.Peach{
  background: linear-gradient(197.48deg, #FF8C5B 32.14%, #FFB979 100%);
}

.Form-header-logo {
  position: relative;
  width: 100%;
}

.indicators {
  position: relative;
  display: flex;
  justify-content: flex-start;
  top: 10%; /* Adjust the distance from the top as needed */
  left: 10%;
}
.indicator{
  width: 5%;
  height: 5px;
  background: #FFFFFF26;
  border-radius: 20px;
  transition: background-color 0.3s;
}
.indicator.Active {
  background-color: #FFFFFF;
}
.text {
  font-family: 'Inter', 'serif';
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 150%;
  color: #FFFFFF;
  text-align: left;
}

.toggle-emoji-button {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.toggle-emoji-button.toggled {
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border-radius: 20px
}

.form-toggle-button {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 7px;
  width: 100%;
  height: 60px;
  border-width: 0;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 0.5em;
}
.form-toggle-button.agent {
  color: #FF6B5B !important;
}

.form-toggle-button-icon {
  width: 40px;
  height: 40px;
}

.form-toggle-button.toggled {
  background-color: #FFFFFF;
  color: #FF8C5B;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.form-textarea {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 7px;
  border: 0;
  width: 100%;
  color: #FFFFFF;
  flex: none;
  order: 0;
  resize: none;
  outline: none;
  padding: 1.5em;
}

.form-textarea::placeholder { /* Modern browsers */
  color: rgba(255, 255, 255, 0.7);
}


.form-textarea::-webkit-scrollbar {
  width: 9px; /* Adjust the width as needed */
}

.form-textarea::-webkit-scrollbar-thumb {
  background-color: #FFFFFF; /* Change the thumb color */
  border-radius: 3px;
  cursor: default;
}

.form-textarea::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.25); /* Change the track color */
  border-radius: 0px 7px 7px 0px;
}


@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.bounce {
  animation: bounce 1s;
}

.next-button {
  width: 300px;
  height: 50px;
  border-radius: 100000px;
  background-color: #FFFFFF;
  font-family: 'Inter', serif;
  border-width: 0;
  font-weight: 700;
}

.next-button:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

.next-button.rate {
  color: #FF6B5B;
}
.next-button.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
.next-button.feedback {
  color: #FF8C5B;
}
.next-button.resolution {
  color: #FFBA3B;
}

.prev-button {
  background-color: transparent;
  border: none;
  font-weight: 700;
  color: #FFFFFF;
  font-family: 'Inter', 'serif';
}

.top-row {
  justify-content: start;
}

.middle-row {
  padding-top: 2.5em;
  padding-bottom: 2.5em;
}

.bottom-row {
  position: relative;
}

.form-thank-you{
  width: 1178px;
}
.group-image{
  position: absolute;
  bottom: 0;
  right: 0;
  pointer-events: none;
  border-radius: 100px;
  user-select: none;
}
.intersect {
  position: absolute;
  left: 0;
  top: 0;
}

.form-thank-you-text{
  font-family: 'Inter', 'serif';
  font-size: 55px;
  font-weight: 700;
  line-height: 82.5px;
  text-align: left;
  color: #FFFFFF;
  position: absolute;
  width: 363px;
  height: 37px;
  bottom: 170px;
}

.form-thank-you-text.small{
  font-family: 'Inter', 'serif';
  font-size: 25px;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
  color: #FFFFFF;
  position: absolute;
  width: 363px;
  height: 37px;
  bottom: 80px;
}

.form-thank-you-logo {
  position: absolute;
  width: 254.23px;
  height: 44px;
  top: 50px;
  z-index: 1;
}
@media screen and (min-width: 1000px) and (max-width: 1399px) {
  .form-thank-you-text{
    text-shadow: 0 0 3px black;
  }
  .form-thank-you-text.small{
    text-shadow: 0 0 3px black;
  }
}



@media screen and (min-width: 601px) and (max-width: 1000px) {
  .container {
    max-width: 100vw !important;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 !important;
    margin-top: 0 !important;
  }

  .Form-header{
    display: flex;
    position: relative;
    justify-content: start;
    align-items: start;
    min-height: 800px;
    height: 100%;
    background: #FF6B5B;
    border-radius: 0;
    padding-left: 10% !important;
  }
  .Form-body.Peach {
    background: #FF8C5B;
  }


  .Form-header-logo{
    position: relative;
    margin-top: 10px;
    width: 200px;
  }

  .Form-body {
    position: absolute;
    justify-content: flex-start;
    min-height: 700px;
    height: calc(100% - 100px);
    top: 40px;
    background: linear-gradient(197.48deg, #FFA05B -9.65%, #FF6B5B 66.23%);
    border-radius: 40px 40px 0 0;
    display: block;
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
  .Form-body.Peach{
    background: linear-gradient(197.48deg, #FFB979 -9.65%, #FF8C5B 66.23%);
  }
  .text {
    text-align: center;
  }

  .indicator{
    width: 10%;
  }
  .intersect {
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .form-thank-you-text{
    font-family: 'Inter', 'serif';
    font-size: 45px;
    font-weight: 700;
    text-shadow: 0 0 3px black;
    line-height: 67.5px;
    text-align: center;
    position: absolute;
    width: 295px;
    height: 37px;
    top: 150px;
  }

  .form-thank-you-text.small{
    font-family: 'Inter', 'serif';
    font-size: 20px;
    text-shadow: 0 0 3px black;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    position: absolute;
    width: 275px;
    height: 75px;
    top: 220px;
  }
  .form-thank-you{
    top: 0;
    border-radius: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
  }
  .bottom-row {
    position: relative;
    bottom: 5%;
  }

  .middle-row {
    align-content: center;
    padding-top: 2.5em;
    padding-bottom: 2.5em;
  }
  .top-row {
    justify-content: center;
    padding-top: 1em;
  }

  .group-image{
    border-radius: 0;
  }

}

@media screen and (max-width: 600px) {
  .container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 !important;
    margin-top: 0;
  }

  .Form-header{
    min-height: 650px;
    display: flex;
    position: relative;
    justify-content: start;
    align-items: start;
    height: 100%;
    background: #FF6B5B;
    border-radius: 0;
    padding-left: 10% !important;
  }
  .Form-body.Peach {
  background: #FF8C5B;
  }

  .Form-header-logo{
    position: relative;
    /*margin-left: 20px;*/
    margin-top: 20px;
    width: 132px;
  }

  .Form-body {
    position: absolute;
    justify-content: flex-start;
    height: calc(100% - 50px);
    bottom: 0;
    background: linear-gradient(197.48deg, #FFA05B -9.65%, #FF6B5B 66.23%);
    border-radius: 40px 40px 0 0;
    display: block;
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
  .Form-body.Peach{
    background: linear-gradient(197.48deg, #FFB979 -9.65%, #FF8C5B 66.23%);
  }
  .bottom-row {
    position: relative;
    padding-top: 1em;
    bottom: 40px;
  }
  .rate-assistance-button{
    width: 88px !important;
    height: 88px !important;
  }
  .text {
    font-family: 'Inter', "serif";
    font-size: 25px;
    font-weight: 700;
    line-height: 37.5px;
    text-align: center;
  }
  .next-button {
    width: 100%;
    height: 40px;
    border-radius: 100000px;
    background-color: #FFFFFF;
    font-family: 'Inter', serif;
    border-width: 0;
    font-weight: 700;

  }
  .indicators {
    position: relative;
    display: flex;
    justify-content: flex-start;
    top: 10%; /* Adjust the distance from the top as needed */
    left: 10%;
  }
  .indicator {
    background-color: #FFFFFF26;
    border-radius: 20px;
    transition: background-color 0.3s;
    width: 45px;
    height: 5px;
  }
  .indicator.Active {
    background: #FFFFFF;
  }
  .prev-button {
    font-family: 'Inter', 'serif';
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    width: 39px;
    height: 20px;
    display: flex;
    align-items: center;
  }

  .form-textarea {
    height: 132px;
  }
  .form-textarea.Large {
    height: 250px;
  }
  .form-thank-you{
    overflow:hidden;
    width: 100%;
    max-height: 900px;
    border-radius: 0;
    background: linear-gradient(197.48deg, #FF6B5B 16.22%, #FFA05B 97.98%);

  }

  .form-thank-you-logo {
    position: absolute;
    width: 162px;
    height: 28.04px;
    top: 50px;
  }
  .intersect {
    position: absolute;
    overflow: hidden;
    top: 30%;
    left: -15%;
  }

  .form-thank-you-text{
    font-family: 'Inter', 'serif';
    font-size: 45px;
    font-weight: 700;
    line-height: 67.5px;
    text-align: center;
    text-shadow: 0 0 3px black;
    position: absolute;
    width: 295px;
    height: 37px;
    top: 117px;
   }

  .form-thank-you-text.small{
    font-family: 'Inter', 'serif';
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    text-shadow: 0 0 3px black;
    position: absolute;
    width: 275px;
    height: 75px;
    top: 187px;
  }
  .form-thank-you{
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
  }
  .top-row {
    justify-content: center;
    padding-top: 1em;
  }
  .middle-row {
    align-content: center;
    padding-top: 2.5em;
    padding-bottom: 2.5em;
  }
  .group-image{
    border-radius: 0;
  }


}